<template>
  <div class="projects-container">
    <div class="projects-flexbox2">
      <div class="headers">
        <div class="header">
          Photography (2019-2023)
        </div>
      </div>
      <div class="grid">
      <div class="square-border" v-for="other in others"
        :key="other.id"
        @click="openPreview(other)">
        <div class="square">
          <img
            :src="other.image"
            class="proj-image"
          >

          <div class="image-subheader">
            {{ other.subheader }}
          </div>
          <div class="image-year">
            {{ other.year }}
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>

  <div
    v-if="isPreviewOpen"
    class="modal-overlay"
    @click.self="closePreview"
  >
    <div class="modal-content">
      <button class="close-button" @click="closePreview">Close x</button>
      <img :src="selectedOther.image" alt="Preview Image" class="modal-image" />
    </div>
  </div>

</template>

<script setup>
import { ref } from 'vue'; 
import Miles1 from '@/assets/img/miles1.jpg'
import Miles2 from '@/assets/img/miles2.jpg'
import Miles3 from '@/assets/img/miles3.jpg'
import Paris1 from '@/assets/img/paris-1.jpg'
import Paris2 from '@/assets/img/paris-2.jpg'
import Paris3 from '@/assets/img/paris-3.jpg'
import Paris4 from '@/assets/img/paris-4.jpg'
import ParisRobina from '@/assets/img/paris-robina.jpg'


const others = [
    {
        subheader: "Miles 1",
        year: "2023",
        image: Miles1
    },
    {
        subheader: "Miles 2",
        year: "2023",
        image: Miles2
    },
    {
        subheader: "Miles 3",
        year: "2023",
        image: Miles3
    },
    {
        subheader: "The Marche des Fiertés LGBT 1",
        year: "2019",
        image: Paris1,
    },
    {
        subheader: "The cutest kiss",
        year: "2019",
        image: Paris2,
    },
    {
        subheader: "Paris",
        year: "2019",
        image: Paris3,
    },
    {
        subheader: "The Marche des Fiertés LGBT 2",
        year: "2019",
        image: Paris4,
    },
    {
        subheader: "Robina in Paris",
        year: "2019",
        image: ParisRobina,
    }
]

const isPreviewOpen = ref(false); 
const selectedOther = ref(null); 

function openPreview(other) {
  selectedOther.value = other;
  isPreviewOpen.value = true;
}

function closePreview() {
  isPreviewOpen.value = false;
}
</script>

<style scoped>
@media screen and (min-width: 800px) and (max-width: 1100px) {

.grid {
  grid-template-columns: 1fr 1fr !important;
  gap: 40px !important;
}

.proj-image {
  height: 600px !important;
}
}

@media screen and (min-width: 600px) and (max-width: 800px) {
.proj-image {
  height: 590px !important;
}

.projects-flexbox2 {
  padding: 9em .7em 3em .7em !important;
}
}

@media screen and (max-width: 600px) {
.proj-image {
  height: 530px !important;
}

.image-subheader {
  margin-top: 0.2em !important;
}

.header {
    font-size: 30px !important;
    max-width: 500px !important;
  }

.subheader {
  font-size: 25px !important;
}

.projects-flexbox2 {
  padding: 7em .7em 3em .7em !important;
}
}

@media screen and (max-width: 800px) {

.image-header {
  font-weight: 800 !important;
  font-size: 23px !important;
}

.grid {
  grid-template-columns: 1fr !important;
  gap: 20px !important;
}

.subheader {
  margin: 0 !important;
}

.projects-flexbox {
  gap: 25px !important;
}

.projects-flexbox2 {
  gap: 30px !important;
}

.modal-image {
  max-width: 95vh !important;
  width: 100% !important;
}

.modal-content {
  max-height: none !important;
  height: 85vh !important;
  justify-content: center !important;
}
}

.headers {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

/* Modal Content */
.modal-content {
  background: black;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  position: relative;
  max-height: 87vh;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.modal-image {
  height: auto;
  border-radius: 8px;
  overflow: hidden;
  max-width: 950px;
  height: auto;
}

.close-button {
  background: transparent;
  border: 1px solid #ff5c5c;
  color: white;
  padding: 8px 12px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
}

.close-button:hover {
  background: #ff3232;
}


.image-year {
    font-family: 'homemade';
    font-size: 21px;
    margin-top: -.2em;
}

.subheader {
    font-family: 'homemade';
    font-size: 45px;
    margin: .5em 0 0 0;
}

.header {
    font-family: 'zilla';
    font-size: 50px;
    margin: 0;
}

.image-subheader {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin-top: .5em;
}

.image-header {
    text-align: center;
    font-size: 25px;
    font-family: 'zilla';
    font-weight: 500;
    margin-top: .8em;
}

.proj-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    height: 500px;
}

.square {
    border-radius: 8px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; 
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    gap: 50px 30px;
    width: 100%;
    max-width: 1300px;
}

.projects-flexbox2 {
    width: 100%;
    max-width: 1300px;
    padding: 10em .7em 3em .7em;
    display: flex;
    flex-direction: column;
    gap: 80px;
    height: 100%;
}

.projects-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
</style>