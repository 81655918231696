<template>
  <div class="nav-container">
    <div class="nav-flexbox-desktop">
    <div class="nav-flexbox2">
      <router-link :to="{ path: '/'}">
        <div class="name">
          Rinette Korea
        </div>
      </router-link>

      <div class="full-menu">
        <div class="right">
          <router-link
            :to="{ path: '/photography'}"
            class="menu-item"
          >
            <div class="menu-item">
              Photography
            </div>
          </router-link>
          <router-link
            :to="{ path: '/design'}"
            class="menu-item"
          >
            <div class="menu-item">
              Design
            </div>
          </router-link>
          <router-link :to="{ path: '/fine-arts'}">
            <div class="menu-item">
              Fine Arts
            </div>
          </router-link>
          <router-link
            :to="{ path: '/resume'}"
            class="menu-item"
          >
            <div class="menu-item">
              Resume
            </div>
          </router-link>
        </div>
      </div>
    </div>
    </div>

    <div class="nav-flexbox-mobile">
      <div class="nav-flexbox2">
        <router-link :to="{ path: '/'}"  @click="nameClicked">
          <div class="name1">
            Rinette Korea
          </div>
        </router-link>

        <font-awesome-icon class="bars" :icon="['fas', 'bars']"  @click="openMobile" v-if="!mobile"/>
        <font-awesome-icon class="bars" :icon="['fas', 'times']"  @click="openMobile" v-if="mobile"/>
      </div>
    </div>
  </div>

  <div class="nav-container">
    
</div>

  <div class="mobile-back" v-if="mobile">
    <div class="nav-flexbox3">
      <div class="inner-menu">
        <router-link  @click="openMobile"
          :to="{ path: '/photography'}"
          class="menu-item"
        >
          <div class="menu-item">
            Photography
          </div>
        </router-link>
        <router-link  @click="openMobile"
          :to="{ path: '/design'}"
          class="menu-item"
        >
          <div class="menu-item">
            Design
          </div>
        </router-link>
        <router-link  @click="openMobile" :to="{ path: '/fine-arts'}">
          <div class="menu-item">
            Fine Arts
          </div>
        </router-link>
        <router-link  @click="openMobile"
          :to="{ path: '/resume'}"
          class="menu-item"
        >
          <div class="menu-item">
            Resume
          </div>
        </router-link>
        <router-link  @click="openMobile"
          :to="{ path: '/', hash: '#contact'}"
          class="menu-item"
        >
          <div class="menu-item">
            Contact
          </div>
        </router-link>
      </div>

      <div class="mobile-copyright">
        ©Rinette Korea 2024. All rights reserved. Email me at rkorea@artdillo.org. Please request permission before using any assets. Will respond to TEXT at (714) 644-1375.
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'; 

const mobile = ref(false);

function openMobile() {
  mobile.value = !mobile.value;
}

function nameClicked () {
  mobile.value = false;
}
</script>

<style scoped>
@media screen and (max-width: 630px) {

  .mobile-copyright {
    font-size: 14px;
    padding: 1em 0em;
  }

  .inner-menu {
    margin-top: 7em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
  }

  .menu-item {
    font-size: 38px !important;
  }

  .nav-flexbox3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100vh;
    padding: 0 .7em;
  }

  .nav-container {
    flex-direction: column !important;
  }

  .mobile-back {
    height: 100vh !important;
    background-color: white;
    width: 100%;
    display: block !important;
    position: fixed;
    z-index: 500;
  }

  .bars {
    font-size: 30px;
    display: block !important;
    z-index: 500;
  }

  .full-menu {
    display: none !important;
  }

  .name1 {
    font-size: 37px !important;
    display: block !important;
  }

  .name, .nav-flexbox-desktop {
    display: none !important;
  }

  .nav-flexbox-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 1.2em .7em;
    align-items: center;
}
  
}

.bars {
    display: none;
  }

.menu-item:hover {
  color: #3464a9;
}

.menu-item {
    color: black;
    font-size: 25px;
    font-weight: 500;
    font-family: 'zilla';
}

.name,.menu-item:hover {
  text-shadow: none !important;
}

.name1 {
    font-family: 'LaBelle';
    font-size: 45px;
    font-weight: 550;
    word-spacing: -12px;
    line-height: 1em;
    margin: 0 0 -.6em 0;
    display: none;
}

.name {
    font-family: 'LaBelle';
    font-size: 45px;
    font-weight: 550;
    word-spacing: -12px;
    line-height: 1em;
    margin: 0 0 -.6em 0;
}

.name:hover {
  color: #cd5b5b;
}

.right {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.nav-flexbox2 {
  padding: 0 .7em;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  z-index: 1000;
}

.nav-flexbox-desktop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 1300px;
    padding: 1.2em .7em;
    align-items: center;
}

.nav-container {
    top: 0; 
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    --bs-navbar-padding-y: 0rem;
    background-color: rgba(255, 255, 255, 0);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    position: fixed;
}
</style>